@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"


































































































.sas-wrapper.content-page
  padding-bottom: 20px
  background: transparent
  padding-bottom: 20px

.content-page
  .sas-box
    border-radius: 0px !important

    +mq-l--mf
      border-radius: $border-radius-m !important

.doc-wrapper
  display: grid
  grid-gap: 32px

  +mq-m--mf
    grid-template-columns: repeat(2, 1fr)

  +mq-l--mf
    grid-template-columns: repeat(4, 1fr)

.main-subtitle
  display: inline-block
  margin-bottom: 36px
  font-size: 22px
  line-height: 28px
  color: #666E75

.simple-separator
  border: 0
  border-bottom: 1px solid #C4C4C4
  margin: 23px 0

.books-filter
  margin: 24px 0 32px 0
  display: inline

  .btn-content
    display: inline
    margin-right: 16px

.btn-book.sas-button
  margin-bottom: 14px
  box-shadow: none
  border: 1px solid #53595F

  &:not(:last-of-type)
    margin-right: 16px

  &:focus
    box-shadow: none !important

  &.active-book
    background: #666E75
    border: 1px solid #666E75 !important
    color: $color-white !important

.container-filters
  width: 100%
  display: flex
  align-items: flex-start
  flex-flow: wrap
  margin-bottom: 24px
  padding-top: 20px

  +mq-m--mf
    flex-direction: row
    grid-gap: 16px
    grid-template-columns: 35% 40% 5% 1fr
    padding-top: 20px

  +mq-l--mf
    flex-direction: row
    grid-gap: 16px
    grid-template-columns: 35% 40% 5% 1fr
    padding-top: 20px
